.header {
  height: 60px;
  background-color: $color-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px solid $color-primary-4;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .headerLeft {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    height: 100%;

    .logo {
      display: $display-flex;
      align-items: $align-item-center;
      height: 100%;
      width: 7vw;

      img {
        height: 100%;
        margin-top: 10px;
      }

      // a {
      //   background: url('../../assets/images/logonew.svg') no-repeat left center;
      //   background-size: contain;
      //   text-indent: -9999px;
      //   display: block;
      //   width: 9vw;
      //   height: 100%;
      // }
      a {
        height: 100%;
      }

      padding-left: 1vw;
    }

    .header-child {
      height: 100%;

      .links {
        display: flex;
        height: 100%;
        align-items: center;

        .link {
          text-decoration: none;
          color: $color-grascale-14;
          line-height: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          margin: 0 1px 0 0;
          text-transform: uppercase;
          font-size: 0.9vw;
          padding: 0 0.5vw;

          &:hover {
            background: $color-primary-4;
            color: $color-white;
          }

          &.active {
            background: $color-primary-4;
            color: $color-white;
            font-weight: bold;
          }
        }
      }
    }
  }

  .headerRight {
    display: flex;
    padding: 5px;
    align-items: $align-item-center;

    .userNameTxt {
      color: $color-grascale-14;
      padding: 0 0 0 10px;
      border-left: 1px solid $color-grascale-14;
      line-height: 32px;
      font-size: 0.9vw;
    }

    .userNameEllipsis {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      line-height: 3.5vh;
      margin-top: 15px;

      @media only screen and (min-width:780px) and (max-width:1200px) {
        max-width: 65px;
      }
    }

    .userNotificationIcon {
      padding: 0 16px;

      svg {
        color: $color-secondary-10;
        font-size: 1.2vw;
      }

      @media (min-width: 80rem) {
        padding: 0 0.625rem;
        padding-left: 0;
      }
    }

    .userSettingsIcon {
      padding: 0 16px;
      cursor: pointer;

      svg {
        color: $color-secondary-10;
        font-size: 1.2vw;
      }

      @media (min-width: 80rem) {
        padding: 0 0.625rem;
      }
    }

    @media (min-width: 80rem) {
      padding-left: 0.0625rem;
    }
  }
}

.gearLink {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none;
}