body,
html {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $color-white;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $color-border;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $color-border;
  }
}

body {
  margin: 0;
  font-family: $font-family-open-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;

  * {
    box-sizing: border-box;
  }

  #root {
    height: 100%;
    overflow: hidden;
  }
}

//overflows
.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}

.formwrapper {
  padding: 0 30px;
}

.formButton {
  margin-top: 36px;
  margin-left: 98px;
}

.react-select-custom {
  width: 225px;
}

.textfield-custom {
  margin-top: 10px;
}

.wrapper {
  border: 1px solid $color-dashed-border;
  border-width: thin;
  padding: 5px;
  display: inline-block;
  vertical-align: top;
  margin: 15px 0 0 15px;
}

.width-100 {
  width: 100%;
}

.ancillary-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.MuiTooltip-tooltip {
  font-size: $font-size-16px !important;
}

// TODO: important should be removed
.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 100%;
  max-width: 100%;
}

.right-side-content {
  max-width: calc(100% - 390px);
  // max-width: calc(100% - 298px);
  margin: 10px 0;
  flex: 1 1 100%;
  overflow: auto;
}


.right-side-content-single-page {
  max-width: 100%;
  margin: 10px 0;
  flex: 1 1 100%;
  overflow: auto;
}

.feature-wrapper {
  margin: 0 0 60px 20px;
  padding-bottom: 100px;

  .MuiGrid-container {
    width: 100%;

    .MuiGrid-item {
      width: 100%;
    }
  }

  .MuiContainer-root {
    .basic-table {
      margin-left: -10px;
    }
  }
}

.toggle-expand {
  display: block;
  width: 30px;
  height: 3.7vh;
  background: url('../../assets/images/icon-expand.png') no-repeat left center;
  background-size: contain;
  cursor: pointer;
  margin-right: 15px;
}

.toggle-collapse {
  display: block;
  width: 30px;
  height: 3.7vh;
  background: url('../../assets/images/icon-collapse.png') no-repeat left center;
  background-size: contain;
  cursor: pointer;
  margin: 10px;
}

.drug-content,
.flex-end {
  .lr-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 65px;
    z-index: 0;
    .sidebar-drawer {
      min-height: 650px;
      -webkit-transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      .MuiDrawer-root {
        width: 100%;
        height: 100%;
        .MuiDrawer-paper {
          position: relative;
          z-index: 1;
        }
        .sideBarMenuOpen {
          height: 100%;
          width: 100%;
          align-self: initial;
          .side-bar-menu {
            height: 100%;
          }
        }
      }
    }

    .sidebar-drawer.drawer-open {
      width: 320px;
    }
    .sidebar-drawer.drawer-close {
      width: 0px;
    }
  }
}



%left-navigation-with-shared {
  width: 320px;
  position: fixed;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
  padding-bottom: 170px;
}

//----------- registry

// .person-routing-wrapper {
  .flex-end {
    .lr-wrapper {
      .sidebar-drawer.drawer-open {
        .MuiDrawer-docked {
          width: 320px;
        }
      }
      .sidebar-drawer.pin-add-menu {
        @extend %left-navigation-with-shared;
      }
      .sidebar-drawer.pin-add-menu.drawer-close {
        position: static;
        width: 0px;
        .MuiDrawer-docked {
          width: 0;
        }
      }
    }
  }
  .right-side-content.pin-add {
    padding-left: 320px;
    overflow: unset;
  }
  .right-side-content.pin-remove {
    padding-left: 0px;
    overflow: unset
  }
// }

.right-side-content.rightPinAdd.r-drawer-open .person-feature-wrapper .toggle-expand{
  position: fixed;
  left: 0;
  z-index: 1;
}

//----------- registry end

//----------------- For clinical ---------------------------- 

.drug-content {
  .lr-wrapper {
    .sidebar-drawer {
      &.drawer-open {
        .MuiDrawer-docked {
          width: 320px;
        }
      }
      &.pin-add-menu {
        @extend %left-navigation-with-shared;
      }
      &.pin-add-menu.drawer-close {
        position: static;
        width: 0px;
      }
      &.drawer-close {
        .MuiDrawer-docked {
          width: 0;
        }
      }
    }
  }
}

.trial-routing-wrapper, 
.drug-routing-wrapper, 
.company-routing-wrapper, 
.organization-routing-wrapper {
  .right-side-content {
    &.pin-add {
      padding-left: 320px;
    }
    &.pin-remove {
      padding-left: 0px;
    }
  }
}
.right-side-content.rightPinAdd.r-drawer-open .feature-wrapper .toggle-expand,
.right-side-content.rightPinAdd.r-drawer-open .toggle-expand{
  position: fixed;
  left: 0;
  z-index: 1;
}

//----------------- For clinical End ----------------------------

//--------------- for reporting start --------------

.reporting-routing-wrapper, .administrator-routing-wrapper {
  .reporting-content, .administrator-content {
    .sidebar-drawer {
      &.drawer-open {
        width: 320px;
        .MuiDrawer-docked {
          width: 320px;
        }
      }
      &.pin-add-menu {
        @extend %left-navigation-with-shared;
      }
      &.pin-add-menu.drawer-close {
        position: static;
        width: 0px;
      }
      &.drawer-close {
        .MuiDrawer-docked {
          width: 0;
        }
      }
    }
    .right-side-content {
      &.pin-add {
        padding-left: 320px;
      }
      &.pin-remove {
        padding-left: 0px;
      }
      &.rightPinAdd.r-drawer-open {
        .toggle-expand {
          position: fixed;
          left: 0;
          z-index: 1;
        }
      }
    }
  }
}


//--------------- for reporting end --------------

//--------------------
.anciliary-routing-wrapper {
  .ancillary-content {
    .sidebar-drawer {
      .pin-add-menu {
        width: 320px;
        position: fixed;
        height: 100vh;
        overflow: hidden;
        overflow-y: auto;
        z-index: 1;
      }
      .pin-remove-menu.drawer-close {
        width: 0px;
        position: static;
      }
    }
    &.pin-add .ancillary-data-feature {
      padding-left: 320px;
    }
    &.pin-remove .ancillary-data-feature {
      padding-left: 0px;
    }
    &.rightPinAdd.r-drawer-open {
      .ancillary-data-feature {
        .toggle-expand {
          position: fixed;
          left: 0;
          margin-left: 0;
          z-index: 1;
        }
      }
    }
  }
}
//--------------------

//============= Overdue start ================
.queue-landing-container {
  .queue-landing-page {
    .sidebar-drawer {
      &.drawer-open {
        .MuiDrawer-docked {
          width: 20vw;
        }
      }
      &.pin-add-menu {
        width: 20vw;
        position: fixed;
        height: 100vh;
        overflow: hidden;
        overflow-y: auto;
        z-index: 1;
        padding-bottom: 150px;
        // padding-top: 140px;
        // top: 0;
      }
      &.drawer-close {
        position: static;
        width: 0px;
        // padding-top: 0px;
        .MuiDrawer-docked {
          width: 0;
        }
      }
      
      .MuiDrawer-docked {
        padding-bottom: 70px;
        .queue-side-bar {
          overflow-x: hidden;
        }
      }
    }
    .right-side-column {
      &.pin-add {
        padding-left: 20vw;
      }
      &.pin-remove{
        padding-left: 0px;
      }
      &.pin-remove.r-drawer-close{
        width: 80%;
      }
      &.pin-remove.r-drawer-open{
        width: 100%;
      }
      &.pin-add.r-drawer-close{
        width: 100%;
      }
      &.pin-add.r-drawer-open{
        width: 100%;
      }

      &.rightPinAdd.r-drawer-open{
        .queue-list-view {
          .table-filter-section {
            .toggle-expand {
              position: fixed;
              left: 0;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

//============= Overdue End ================

.showPinIcon{
  background: no-repeat url('../../assets/images/pin.png');
  background-position: left center;
  background-size: contain;
  display: inline-block;
  margin-right: 10px;
  height: 3.7vh;
  width: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.showPinIcon.pinActive{
  background: no-repeat url('../../assets/images/pin.png');
  background-position: left center;
  background-size: contain;
}
.showPinIcon.pinDeactivate{
  background: no-repeat url('../../assets/images/unPin.png');
  background-position: left center;
  background-size: contain;
}
