 /*SIDEBAR  CSS STARTS*/
 .sidebar-wrapper {
    /* height: 700px; */
    width: 200px;
    border: 1px solid lightgray;
    border-width: thin;
    padding: 15px 10px 10px 10px;
    margin-left: 18px;
    margin-top: 15px;
    display: inline-block;
  }
  .sidebar {
    width: 200px;
    /* overflow-y: scroll; */
    background-color: #9da7bd;
    color: $color-white;
    font-family: $font-family-normal;
    border: 1px solid lightgrey;
  }
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 600px;
  }
  
  .sidebar li {
    padding: 10px;
    border-bottom: 1px solid $color-white;
    font-size: $font-size-12px;
  }
  .sidebar-li {
    background-color:  #333;
  }
  .sidebar-heading {
    background: $color-white;
    padding: 0 3px 0 3px;
    font-weight: 600;
    margin-top: -29px;
    width: fit-content;
  }
  .sidebar li:hover {
    background-color: #333;
    cursor: pointer;
  }
  .sidebar-children {
    margin-left: 250px;
  }
  
  .sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $color-white;
  }
  
  .sidebar::-webkit-scrollbar {
    width: 8px;
    background-color: $color-white;
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $color-tertiary-11;
  }
  
  /*SIDEBAR  CSS ENDS*/