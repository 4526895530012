// Header variables
$header-height: 90px;
$header-full-height: 93px;

// Font variables
$font-family-normal: Helvetica,
Arial,
sans-serif;
$font-family-open-sans: 'Open Sans',
sans-serif;
$font-line-height: 1.4;
$font-weight: (regular: normal,
  light: 300,
  medium: 500,
  mediumBold: 600,
  bold: 700,
  heavy: 900,
  book: normal,
);

@function make_color($c, $o) {
  @return mix($c, $color-white, percentage($o/100));
}

// primary colors
$color-primary-1: #381e56;
$color-primary-2: #5f4893;
$color-primary-3: #63428c;
$color-primary-4: #ce5182;

//secondary colors
$color-secondary-1: #1191d1;
$color-secondary-2: #ef426f;
$color-secondary-3: #efe342;
$color-secondary-4: #00a7b5;
$color-secondary-5: #efa442;
$color-secondary-6: #d22f2f;
$color-secondary-7: #de8505;
$color-secondary-8: #00a247;
$color-secondary-9: #f9ecf1;
$color-secondary-10: #d1497c;
$color-secondary-11: #fa065b;
$color-secondary-12: #0fb908;

// Grayscale colors:
$color-grayscale-0: #ffffff;
$color-grayscale-1: #eceff2;
$color-grayscale-2: #f0f1f3;
$color-grayscale-3: #688197;
$color-grayscale-4: #254a5d;
$color-grayscale-5: #1d252d;
$color-grayscale-6: #707070;
$color-grayscale-7: #6b7385;
$color-grayscale-8: #c3c7cf;
$color-grayscale-9: #484a59;
$color-grayscale-10: #000000;
$color-grayscale-11: #a6aab6;
$color-grascale-12: #e3e5e9;
$color-grascale-13: #666666;
$color-grascale-14: #6a7286;
$color-grayscale-15: #9397a5;
$color-grayscale-16: #4b5260;

//tertiary colors

$color-tertiary-1: #68d2df;
$color-tertiary-2: #efecf4;
$color-tertiary-3: #0f5487;
$color-tertiary-4: #a384c8;
$color-tertiary-5: #f6f09b;
$color-tertiary-6: #e0f4e9;
$color-tertiary-7: #e2f2f9;
$color-tertiary-8: #fdf4e8;
$color-tertiary-9: #fde8ed;
$color-tertiary-10: #fbf9d8;
$color-tertiary-11: #e8e8e8;
$color-tertiary-12:#2bede3;

// // Primary colors:
// $color-primary-0: $color-grayscale-4;

// $color-primary-1-lighter: lighten($color-primary-1, 25);
// $color-primary-1-darker: darken($color-primary-1, 10);

// $color-primary-2-lighter: lighten($color-primary-2, 10);
// $color-primary-2-darker: darken($color-primary-2, 10);

// Secondary colors:
// $color-secondary-0: #CE5182;
// $color-secondary-1: #68d2df;

// Color aliases:
$color-white: $color-grayscale-0;
$color-black: $color-grayscale-5;
$color-default: $color-grayscale-4;
$color-disabled: $color-grayscale-2;
$color-pink: $color-primary-4;
$color-border: $color-grayscale-11;
$color-dashed-border: $color-grayscale-8;
$color-input-text: $color-grayscale-9;
$color-pure-black: $color-grayscale-10;

//Label color
$color-label: $color-grayscale-9;

// Button color
$color-btn-hover: $color-primary-3;
$color-btn-focus: $color-primary-1;
$color-btn-active: $color-primary-1;
$color-btn-disabled: $color-grascale-12;

// Input color
$color-input-bg-disabled: $color-grayscale-2;

//Misc colors
$color-yellow-0: $color-secondary-3;
$color-red-0: $color-secondary-2;
$color-red-1: $color-secondary-6;
$color-orange-0: $color-secondary-5;
$color-green-0: $color-secondary-8;
$color-green-1: $color-secondary-12;
$color-blue-0: $color-secondary-1;
$color-asterix: $color-red-0;
$color-highlight: $color-tertiary-5;
$color-light-blue: $color-tertiary-12;

//success colors
$bg-color-success: #e0f4e8;
$bg-color-error: $color-tertiary-9;
$bg-color-danger: $color-tertiary-9;
$bg-color-warning: $color-tertiary-8;
$bg-color-info: #e2f2fa;

$color-success: $color-secondary-8;
$color-error: $color-secondary-2;
$color-danger: $color-secondary-2;
$color-warning: $color-secondary-5;
$color-info: $color-secondary-1;

$color-link: $color-primary-1;
$color-icon: $color-secondary-11;

$spacing-xxs: 5px;
$spacing-xs: 10px;
$spacing-xs-double: 20px;
$spacing-sm: 15px;
$spacing-sm-double: 30px;
$spacing-md: 25px;
$spacing-md-double: 50px;
$spacing-lg: 40px;
$spacing-lg-double: 80px;
$spacing-xl: 65px;
$spacing-xl-double: 130px;

//font sizes

$font-size-10px: 0.52vw;
$font-size-11px: 0.57vw;
$font-size-12px: 0.625vw;
$font-size-13px: 0.67vw;
$font-size-14px: 0.73vw;
$font-size-15px: 0.78vw;
$font-size-16px: 0.833vw;
$font-size-17px: 0.88vw;
$font-size-18px: 0.93vw;
$font-size-19px: 0.99vw;
$font-size-20px: 1.04vw;
$font-size-22px: 1.14vw;
$font-size-24px: 1.25vw;
$font-size-26px: 1.40vw;

$table-cell-font-size: $font-size-16px;
$table-cell-max-height: 38px;
$table-cell-input-max-width: 55px;
$table-cell-input-margin-left: 20px;

// display prpoerties
$display-flex: flex;
$display-block: block;
$display-inline-block: inline-block;

// Align items
$align-item-top: flex-start;
$align-item-center: center;
$align-item-bottom: flex-end;

// Border radius
$border-radius: 4px;