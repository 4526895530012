@mixin button-style($bg-color, $color, $border-color) {
  background-color: $bg-color;
  color: $color;
  border: 2px solid $border-color;
}
@mixin border($border-width, $border-style, $border-color){
  border: $border-width $border-style $border-color;
}
@mixin border-right($border-width, $border-style, $border-color) {
  border-right: $border-width $border-style $border-color;
}

@mixin border-bottom($border-width, $border-style, $border-color) {
  border-bottom: $border-width $border-style $border-color;
}

@mixin button-focus{
  border: 3px solid $color-btn-focus;
}

@mixin button-active{
  border: 3px solid $color-btn-active;
}

@mixin button-hover{
  border: 3px solid $color-btn-hover;
}

@mixin button-disabled{
  border: 3px solid $color-grascale-12;
  background-color: $color-grascale-12;
  color:$color-grayscale-11;
  text-transform: uppercase;
  cursor: not-allowed;
  letter-spacing: 0;
}

@mixin plus-button {
  border: none;
  background: none;
  color: $color-pink;
}

@mixin fa-icon($font-size, $color) {
  font-size: $font-size;
  color: $color;
  border: none;
  background: none;
}