@import '../variables.scss';
@import './icon.mixins.scss';
$icon-path: '../../../node_modules/ag-grid-community/src/styles/ag-theme-balham/icons/';

// @mixin icon-background($path, $icon, $size) {
//   background: url('#{$path}#{$icon}.svg') no-repeat;
//   background-color: transparent;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: $size $size;
//   height: $size;
//   width: $size;
// }

@mixin make_grid_base($advanced-table: true) {
  $color: $color-default;
  font-family: $font-family-open-sans;
  font-size: $table-cell-font-size;
  font-weight: 500;

  .ag-root {
    border: 0px;
  }

  .ag-root-wrapper-body {
    height: 100%;
  }

  .ag-overlay {
    padding: 20px;
    position: relative;
  }

  .ag-center-cols-viewport {
    height: 100% !important;
    width: calc(100% + 1px);
  }

  .ag-header {
    height: 70px;
    font-family: $font-family-open-sans;
    font-size: $table-cell-font-size;
  }

  .ag-header-cell {
    @if $advanced-table {
      outline: none !important;
      padding: 15px;
      text-transform: uppercase;
      background: $color-primary-1;
      color: $color-grayscale-1;
    } @else {
      background: $color-white;
      color: $color-primary-2;
    }

    .ag-header-cell-text {
      white-space: normal;
      font-size: $font-size-18px;
      text-transform: uppercase;
    }

    .ag-sort-ascending-icon {
      color: $color-primary-4;

      i {
        font-size: $font-size-16px;
      }
    }

    .ag-sort-descending-icon {
      color: $color-primary-4;

      i {
        font-size: $font-size-16px;
      }
    }

    .ag-icon {
      color: $color-primary-4;
      font-weight: bold;
    }
  }

  .ag-row {
    font-family: $font-family-open-sans;
    font-size: $table-cell-font-size;
    color: $color-label;

    @if $advanced-table {
      border-style: none;

      &-even {
        background-color: rgba($color-grayscale-4, 0.05);

        &:hover {
          background-color: rgba($color-grayscale-4, 0.1);
        }
      }

      &-odd {
        background-color: transparent;

        &:hover {
          background: rgba($color-grayscale-4, 0.1);
        }
      }
    } @else {
      background-color: $color-white;
    }
  }
}

@mixin show_column_resize() {
  .ag-header-cell::after {
    @include border-right(2px, solid, $color-dashed-border);
    margin: auto;
    top: inherit;
  }
  .ag-header-row .ag-header-cell:last-child:not(.disableResize) {
    margin-right: 1px;
  }
}
