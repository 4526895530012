@for $i from 0 through 100 {

  // margin

  .#{"m"}-#{$i} {
    margin: $i#{"px"};
  }

  .#{"mr"}-#{$i} {
    margin-right: $i#{"px"};
  }

  .#{"ml"}-#{$i} {
    margin-left: $i#{"px"};
  }

  .#{"mt"}-#{$i} {
    margin-top: $i#{"px"};
  }

  .#{"mb"}-#{$i} {
    margin-bottom: $i#{"px"};
  }

  //padding

  .#{"p"}-#{$i} {
    padding: $i#{"px"};
  }

  .#{"pr"}-#{$i} {
    padding-right: $i#{"px"};
  }

  .#{"pl"}-#{$i} {
    padding-left: $i#{"px"};
  }

  .#{"pt"}-#{$i} {
    padding-top: $i#{"px"};
  }

  .#{"pb"}-#{$i} {
    padding-bottom: $i#{"px"};
  }

  // border-radius

  .#{"br"}-#{$i} {
    -webkit-border-radius: $i#{"px"};
    -moz-border-radius: $i#{"px"};
    -ms-border-radius: $i#{"px"};
    border-radius: $i#{"px"};
  }

  // border

  .#{"b"}-#{$i} {
    border: $i#{"px"};
  }

  // z-index

  .#{"z"}-#{$i} {
    z-index: $i;
  }

}