// functions 
@function remy($pxsize){
    @return($pxsize / 16)+rem;
}
// desktop responsive breakpoints

// recommended screen 150%
$screen-150-prcnt:1180px;
// screen 125%
$screen-125-prcnt: 1480px;
// 100% screen 
$screen-100-prcnt: 1800px;




// small device mobiles
@mixin rcommended-150 {
    @media (min-width: #{$screen-150-prcnt}) {
        @content;
    }
}

// medium device small tablets
@mixin screen-125 {
    @media (min-width: #{$screen-125-prcnt}) {
        @content;
    }
}

// medium desktop device tablets 
@mixin screen-100-prcnt {
    @media (min-width: #{$screen-100-prcnt}){
        @content;
    }
}

//  interpolate between over a set of viewport widths.

@function between($from, $to, $fromWidth, $toWidth) {
    $slope: ($to - $from) / ($toWidth - $fromWidth);
    $base: $from - $slope * $fromWidth;
  
    @return calc(#{$base} + #{100vw * $slope});
}
$min: 1290px;
$max: 1920px;
