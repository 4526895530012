.button {
  background-color: $color-grascale-12;
  color: $color-white;
  height: 35px;
  width: 116px;
  border: 2px solid $color-grascale-12;
  border-radius: 2px;
  cursor: pointer;
  opacity: 1;
  font-size: $font-size-20px;
}

/*INPUT CSS STARTS*/

.input-container {
  background-color: $color-white;
  /* border: 1px solid grey; */
  width: 300px;
  height: 25px;
  font-size: $font-size-16px;
  border-radius: 5px;
  margin-top: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}
.input-container .select {
  height: 33px !important;
}
.input-container-left-icon,
.input-container-right-icon {
  background-color: $color-white;
  /* border: 1px solid grey; */
  width: 300px;
  height: 25px;
  font-size: $font-size-16px;
  border-radius: 5px;
  margin-top: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid grey;
}
.input-container-left-icon input,
.input-container-right-icon input {
  border: none !important;
  display: inline-block;
  width: 255px;
  margin-top: -3px;
}
.title {
  padding-left: 5px;
  font-size: $font-size-14px;
  color: grey;
}
.partition {
  position: absolute;
  left: 18px;
  bottom: 5px;
  opacity: 0.5;
  color: grey;
}
input:focus {
  outline: none;
}
.left-icon {
  position: absolute;
  left: 4px;
  top: 7px;
}
.left-partiton {
  padding: 0;
  display: inline;
  margin-left: 12px;
}
.right-icon {
  color: grey;
  position: absolute;
  top: 8px;
}

.input-ingested-text {
  color: $color-green-0;
  padding: 10px 0px 0px 10px;
  margin: 0;
  font-size: $font-size-16px;
}

.ingested-default-text {
  color: $color-green-0;
}

/*INPUT CSS ENDS*/
