@import '../variables.scss';

// Text Area Mixin
@mixin make_textarea($height, $width) {
  width: $width;
  height: $height;
  border: 2px solid $color-grayscale-2;
  border-radius: 4px;
}

// Label Mixin
@mixin label($font-size, $font-weight) {
  font: {
    size: $font-size;
    weight: $font-weight;
    family: $font-family-open-sans;
  }

  color: $color-label;
  text-align: left;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;
}

// Input Text Field Mixin
@mixin input {
  border: 1px solid $color-grayscale-11;
  border-radius: 4px;
  background-color: $color-white;
  opacity: 1;
}


// Input Focus Mixin
@mixin input-focus {
  outline: 1px solid $color-grayscale-11;
  border-radius: $border-radius;
}

// Input Hover Mixin
@mixin input-hover {
  outline: 1px solid $color-grayscale-11;
  border-radius: $border-radius;
}

@mixin error-text {
  font-size: $font-size-16px;
  color: $color-error;
  margin-top: 2px;
  line-height: 22px;
}

@mixin border-dashed($border-width, $border-style, $border-color) {
  border-right: $border-width $border-style $border-color;
}

@mixin input-disabled{
  border: 1px solid $color-grayscale-11;
  background-color: $color-grayscale-2;
  color:$color-grayscale-11;
  cursor: not-allowed;
}